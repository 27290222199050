import { createApp } from 'vue'
import { VueCookieNext } from 'vue-cookie-next'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Toast from 'vue-toastification'

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .use(i18n)
  .use(Toast, {
    transition: 'Vue-Toastification__bounce',
    maxToasts: 10,
    newestOnTop: true,
    position: 'top-center',
    timeout: 15000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
    closeButton: 'a',
    icon: true,
    rtl: false
  })
  .mount('#app')
